// |--------------------------------------------------------------------------
// | BS3 sidebar
// |--------------------------------------------------------------------------
// |
// | App alike navigation with sidebar.
// |
// | This jQuery script is written by
// | Morten Nissen
// |
var bs3Sidebar = (function ($) {
    'use strict';

    var pub = {};

    /**
     * Instantiate
     */
    pub.init = function (options) {
        registerEventHandlers();
        registerBootEventHandlers();
    }

    /**
     * Register event handlers
     */
    function registerEventHandlers() {

        // Toggle sidebar
        $('[data-sidebar-toggle]').on('click touchstart', function (event) {
            event.preventDefault();

            var $element = $(this);
            toggleSidebar($element);
        });

        // Toggle dropdown
        $('.sidebar .sidebar-navigation-dropdown > a > .sidebar-navigation-dropdown-toggle').on('click touchstart', function (event) {
            event.preventDefault();

            var $element = $(this);

            toggleDropdown($element);
        });
    }

    /**
     * Register boot event handlers
     */
    function registerBootEventHandlers() {
    }

    /**
     * Toggle sidebar
     */
    function toggleSidebar($element) {
        var $body = $('.body-replacement');
        var attribute = $element.attr('data-sidebar-toggle');

        if (attribute != 'left' && attribute != 'right') {
            return false;
        }

        if (attribute == 'left' && $body.hasClass('sidebar-right-open')) {
            $body.removeClass('sidebar-right-open');
        }

        if (attribute == 'right' && $body.hasClass('sidebar-left-open')) {
            $body.removeClass('sidebar-left-open');
        }

        $body.toggleClass('sidebar-' + attribute + '-open');
    }

    /**
     * Toggle dropdown
     */
    function toggleDropdown($element) {
        var $parent = $element.parent().parent();
        var parentIsActive = $parent.hasClass('active') || $parent.hasClass('active-trail') ? true : false;

        if (parentIsActive) {
            closeDropdown($parent);
        }

        else {
            openDropdown($parent);
        }
    }

    /**
     * Open dropdown
     */
    function openDropdown($parent) {
        var $dropdownMenu = $parent.find('> .sidebar-navigation-dropdown-menu');
        var dropdownMenuHeight = $dropdownMenu.outerHeight(true);
        var preAnimationCSS = {opacity: 0.1, height: 0, top: -20};
        var animationCSS = {opacity: 1, height: dropdownMenuHeight, top: 0};
        var callbackFunction = function () {
            $dropdownMenu.attr('style', '');
        };

        closeAllDropdownMenus($parent);

        $parent.addClass('active');

        $dropdownMenu
            .addClass('active')
            .css(preAnimationCSS);

        dropdownMenuAnimatedToggle($dropdownMenu, animationCSS, callbackFunction);
    }

    /**
     * Close dropdown
     */
    function closeDropdown($parent) {
        var $dropdownMenu = $parent.find('> .sidebar-navigation-dropdown-menu');
        var animationCSS = {height: 0, opacity: 0.1};
        var callbackFunction = function () {

            // Remove all active class' from dropdown menu and all child elements with active states
            $dropdownMenu
                .removeClass('active')
                .attr('style', '')
                .find('.active:not(a)')
                .removeClass('active')
                .attr('style', '');

            $dropdownMenu
                .removeClass('active-trail')
                .attr('style', '')
                .find('.active-trail:not(a)')
                .removeClass('active-trail')
                .attr('style', '');
        };

        $parent
            .removeClass('active')
            .removeClass('active-trail');

        dropdownMenuAnimatedToggle($dropdownMenu, animationCSS, callbackFunction);
    }

    /**
     * Close all dropdown menus
     */
    function closeAllDropdownMenus($parent) {
        $parent
            .siblings('.sidebar-navigation-dropdown.active, .sidebar-navigation-dropdown.active-trail')
            .each(function () {
                var $element = $(this);

                closeDropdown($element);
            });
    }

    /**
     * Dropdown menu animated toggle
     */
    function dropdownMenuAnimatedToggle($dropdownMenu, animationCSS, callbackFunction) {
        $dropdownMenu.animate(
            animationCSS,
            {
                duration: 400,
                easing: 'easeOutSine',
                complete: callbackFunction
            });
    }

    return pub;
})(jQuery);
