// Document ready
jQuery(document).ready(function($){
  'use strict';
  // --------------------------------------------------
  // Sidebar
  // --------------------------------------------------
  selfServiceLinks.init();
  bs3Sidebar.init();
  bs3SimpleNavigation.init();
});
